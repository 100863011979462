/* eslint-disable prettier/prettier */
import Vue from "vue";
const { __getText } = Vue.prototype;
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
// import { isAfter } from "date-fns";
import {
  occupiedDailyOptions,
  roofStructureOptions,
  yearOptions
} from "@/helpers/selectOptions";
import { isPoolInApprovedFence, validateOccupiedDaily } from "../utils/quotes";
export const numberOfStoriesOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" }
];

const yesNoOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" }
];
export default function(policyType?: string, companyNumbers?: Array<number>) {
  return [
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useTextField({
              key: "replacementCost",
              label: "Replacement Cost",
              placeholder: "Replacement Cost",
              required: policyType !== "T",
              validations: [
                {
                  method: "integer",
                  error: __getText("fields", "numberError")
                }
              ]
            }),
            useTextField({
              key: "marketValue",
              label: "Market Value",
              placeholder: "Market Value",
              required: policyType !== "T",
              validations: [
                {
                  method: "integer",
                  error: __getText("fields", "numberError")
                }
              ]
            }),
            useSelectField({
              key: "numberOfStories",
              label: "Number Of Stories",
              options: numberOfStoriesOptions,
              required: true,
              filterable: true,
              allowFirstOption: true
            })
          ],
          { layout: "3-col" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "roofStructure",
              label: "Roof Structure",
              options: roofStructureOptions,
              required: true,
              type: "custom-roof-structure-field",
              filterable: true
            }),
            useSelectField({
              key: "occupiedDaily",
              label: "Occupied Daily",
              options: occupiedDailyOptions,
              required: true,
              validations: [
                {
                  method: "custom",
                  error: "Vacant dwelling not allowed",
                  customValidator: (value: string) => {
                    return validateOccupiedDaily(
                      value,
                      policyType,
                      companyNumbers
                    );
                  }
                }
              ]
            }),
            useField({
              key: "purchaseDate",
              label: "Purchase Date",
              required: policyType !== "T",
              type: "date",
              pickerOptions: {}
            })
          ],
          { layout: "3-col" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "swimmingPoolOnPremises",
              preamble: "Is there a swimming pool on premises?",
              type: "radio_button_group",
              options: yesNoOptions,
              required: true
            })
          ],
          { layout: "full" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "poolApprovedFence",
              preamble: "Approved Fence?",
              type: "radio_button_group",
              options: yesNoOptions,
              conditions: {
                or: [
                  {
                    field: "swimmingPoolOnPremises",
                    value: 1,
                    operator: "equals"
                  }
                ]
              },
              validations: [
                {
                  method: "custom",
                  error: "Risk Prohibited",
                  customValidator: (value: number) => {
                    return isPoolInApprovedFence(value, policyType);
                  }
                }
              ]
            }),
            useField({
              key: "poolDivingBoard",
              preamble: "Diving Board?",
              type: "radio_button_group",
              options: yesNoOptions,
              conditions: {
                or: [
                  {
                    field: "swimmingPoolOnPremises",
                    value: 1,
                    operator: "equals"
                  }
                ]
              }
            }),
            useField({
              key: "poolSlide",
              preamble: "Slide?",
              type: "radio_button_group",
              options: yesNoOptions,
              conditions: {
                or: [
                  {
                    field: "swimmingPoolOnPremises",
                    value: 1,
                    operator: "equals"
                  }
                ]
              }
            })
          ],
          { layout: "3-col" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "propertyUpdates",
              preamble: "Have there been any updates to the property?",
              type: "radio_button_group",
              options: yesNoOptions,
              required: true
            })
          ],
          { layout: "full" }
        ),
        ...useFormFieldGroup(
          [
            useSelectField({
              key: "wiringUpdateYear",
              label: "Wiring Update (Year)",
              options: yearOptions,
              required: false,
              filterable: true,
              allowFirstOption: true,

              conditions: {
                or: [
                  {
                    field: "propertyUpdates",
                    value: 1,
                    operator: "equals"
                  }
                ]
              }
            }),
            useSelectField({
              key: "plumbingUpdateYear",
              label: "Plumbing Update (Year)",
              options: yearOptions,
              required: false,
              filterable: true,
              allowFirstOption: true,

              conditions: {
                or: [
                  {
                    field: "propertyUpdates",
                    value: 1,
                    operator: "equals"
                  }
                ]
              }
            }),
            useSelectField({
              key: "heatingUpdateYear",
              label: "Heating Update (Year)",
              options: yearOptions,
              required: false,
              filterable: true,
              allowFirstOption: true,

              conditions: {
                or: [
                  {
                    field: "propertyUpdates",
                    value: 1,
                    operator: "equals"
                  }
                ]
              }
            })
          ],
          { layout: "3-col" }
        )
      ],
      { title: "Underwriting" }
    )
  ];
}
