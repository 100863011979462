import {
  useFormFieldGroup,
  useSelectField,
  useField,
  useFormSection,
  useTextField
} from "@/components/FormBuilder/Helpers";
import {
  yearOptions,
  yearBuiltOptions,
  liabilityLimit,
  medLiability,
  occupancyOptions,
  billingPlanOptions,
  without26
} from "@/helpers/selectOptions";
import sharedUnderwritingQuestions from "@/forms/shared/quote.shared_underwriter_questions";
import { isValidEffectiveDate } from "@/helpers/validateEffectiveDate";
import { getBillingErrorSection } from "../utils";
import { ICompaniesData } from "./quote.home_owner";

export const tenantDwellingOptions = [
  { label: "Dwelling/Townhouse = BT", value: "0" },
  { label: "Apartment = BT", value: "3" },
  { label: "Condo = B-Con", value: "4" }
];

export const tenantStructureTypeOptions = [
  { label: "Brick", value: "M" },
  { label: "Brick Veneer", value: "B" },
  { label: "Stucco", value: "A" },
  { label: "Frame", value: "F" }
];

export const tenantLimitDed3Options = [
  { label: "1%", value: "1" },
  { label: "1%/ $250", value: "2" },
  { label: `$250 ${without26}`, value: "5" },
  { label: "1/2%", value: "6" },
  { label: "1/2% /$250", value: "7" }
];

export const tenantEntranceOptions = [
  { label: "Non-Shared", value: "N" },
  { label: "Shared", value: "Y" }
];

export default function(data: ICompaniesData) {
  const { companyNumbers, billingValue, user, policyType, createdOn } = data;
  const billingErrorSection = getBillingErrorSection(billingValue);

  return [
    ...useFormFieldGroup(
      [
        useField({
          key: "effectiveDate",
          label: "Effective Date",
          required: true,
          type: "date",
          pickerOptions: {
            disabledDate: (fieldValue: Date) =>
              !isValidEffectiveDate(fieldValue, createdOn, user)
          }
        }),
        useSelectField({
          key: "paymentPlan",
          label: "Payment Plan",
          options: [
            { label: "Bill Insured", value: "1" },
            { label: "Bill Mortgagee(in full only)", value: "2" }
          ],
          required: true
        }),
        useSelectField({
          key: "billing",
          label: "Billing Plan",
          options: billingPlanOptions,
          required: true,
          conditions: {
            and: [
              {
                field: "paymentPlan",
                value: "1",
                operator: "equals"
              }
            ]
          }
        })
      ],
      { layout: "3-col" }
    ),
    ...billingErrorSection,
    ...useFormSection(
      [
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useSelectField({
                  key: "dwellType",
                  label: "Dwelling Type",
                  options: tenantDwellingOptions,
                  required: true
                }),
                useSelectField({
                  key: "structureType",
                  label: "Building Structure Type",
                  options: tenantStructureTypeOptions,
                  required: true
                })
              ],
              { layout: "3-col" }
            )
          ],
          {
            title: "Property Usage & Construction"
          }
        ),
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useSelectField({
                  key: "limitDed3",
                  label: "Ded 3",
                  options: tenantLimitDed3Options,
                  required: true
                }),
                useSelectField({
                  key: "limitLiab",
                  label: "Liability Limit",
                  options: liabilityLimit,
                  required: true
                }),
                useSelectField({
                  key: "entrance",
                  label: "Entrance",
                  options: tenantEntranceOptions,
                  required: true
                }),
                useSelectField({
                  key: "limitMed",
                  label: "Med Limit",
                  options: medLiability,
                  required: true
                })
              ],
              { layout: "4-col" }
            )
          ],
          {
            title: "Limits and Deductible Modifiers"
          }
        ),
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useSelectField({
                  key: "yearBuild",
                  label: "Year Built",
                  options: yearBuiltOptions,
                  required: true,
                  filterable: true,
                  allowCreate: true,
                  allowFirstOption: true,
                  conditions: {
                    and: [
                      {
                        operator: "hasValue",
                        field: "companyNumbers",
                        value: true
                      }
                    ]
                  },
                  validations: [
                    {
                      method: "custom",
                      customValidator: (value: any) => {
                        if (!value) return true;
                        return !isNaN(value) && `${value}`.length === 4;
                      },
                      error: "Must be a year!"
                    }
                  ]
                }),
                useSelectField({
                  key: "yearOfRoof",
                  label: "Roof Year",
                  options: yearOptions,
                  required: false,
                  filterable: true,
                  allowFirstOption: true,
                  conditions: {
                    and: [
                      {
                        operator: "hasValue",
                        field: "companyNumbers",
                        value: true
                      }
                    ]
                  }
                }),
                useTextField({
                  key: "amountUPP",
                  label: "Contents Amount",
                  required: true,
                  validations: [
                    { method: "integer", error: "Please enter a number" },
                    {
                      method: "minValue",
                      value: 20000,
                      error: "Content amount must be at least $20000"
                    },
                    {
                      method: "maxValue",
                      value: 150000,
                      error: "Content amount must not exceed $150000"
                    }
                  ]
                }),
                useSelectField({
                  key: "occupancy",
                  label: "Occupancy",
                  options: occupancyOptions,
                  required: true
                })
              ],
              { layout: "4-col" }
            ),
            useField(
              {
                type: "radio_button_group",
                preamble: "Trampoline on the premises?",
                key: "trampolineOnPremises",
                options: [
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" }
                ],
                required: true
              },
              ["mb-8"]
            ),
            useField({
              type: "radio_button_group",
              preamble: "Number of Non-Weather or Non-Appliance Claims",
              key: "numberOfNonWeatherClaims",
              options: [0, 1, 2, 3].map((op: number) => ({
                value: op,
                label: op.toString()
              })),
              required: true
            })
          ],
          {
            title: "Dwelling Information"
          }
        )
      ],
      {
        title: "Property Information"
      }
    ),
    ...sharedUnderwritingQuestions(policyType, companyNumbers)
  ];
}
